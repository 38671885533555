<!-- Finder particular de Jefes de Zona -->

<template>
  <div class="jefeszonaF" v-if="loaded">
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">
          
        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:800px"> 
          <div class="conflex" style="width:65%">
            <uiText               
              style="width:50%;"
              v-model="ct.name[2]"
              :label="ct.name[1]"
              @Enter="eventFiltro('buscar')"> 
            </uiText>
                
            <v-btn 
              v-bind="$cfe.btn.busca" 
              style="margin-left:5px"
              @click="eventFiltro('buscar')">
                <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
            </v-btn>
          </div>
        

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">

              <!-- Slot Botonera Top Grid -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <!-- Slot Acciones Row -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>            
          </baseGridMD>
        </div>
      </v-sheet>


      <!-- Mto Coordinador de Servicios -->
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";  
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import jefeszonaM from "@/components/jefeszonaM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, jefeszonaM },
    props: {},

    data() {
      return {
        stIni: {
          api: "jefeszonaF",
          titulo:"Coordinador de Servicios", 
          name: "jefeszonaF",
          mView: 'jefeszonaM',
          pView:[]
        },
      
      };
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
          // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
          // si solo hubiera un elemento la cabecera tomará este por defecto 
          // Si no encontrara el nombre pondría el elemento 0
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "name", witdh: "30%" },
            { text: "Código", value: "codigo", witdh: "60%" }
          ]}          
        ];      

        this.headerGrid= this.headerSet();       
      },  

    }
  };
</script>
