<!-- Mto particular de Jefes de Zona -->

<template>
  <div class="jefeszonaM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"      
       persistent>
      
        <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>  
            
            <v-sheet :elevation="4">
              <div class="contenedor" style="width:1000px">

                <div class="conflex">
                  <!-- Botoneras -->

                  <!-- Mto --> 
                  <div>                 
                    <baseBtraMto
                      v-if="!readOnly"
                      :perm="permMto"
                      :modulo="btMtoCfg" 
                      :estado="estado"
                      @onEvent="execAccion">        
                    </baseBtraMto>
                  </div>

                  <!-- Extra -->
                  <div class="conflex" style="margin-left: 50px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>                 
                </div>


                <!-- Controles del Mto -->
                <div class="conflex" style="padding-top:10px;align-items:flex-start">
                  <div class="columna" style="width:690px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                      
                    <uiText
                      v-bind="$input" 
                      style="font-weight: bold;"                    
                      v-model="ct.name[2]"
                      :label="ct.name[1]"
                      :disabled="noEdit">                      
                    </uiText>                    
                  
                    <div class="conflex">
                      <uiText
                        v-bind="$input" 
                        style="width:69%"                    
                        v-model="ct.codigo[2]"
                        :label="ct.codigo[1]"
                        :disabled="noEdit">                      
                      </uiText>

                      <uiText
                        v-bind="$input" 
                        style="width:30%;margin-left:5px"                    
                        v-model="ct.nif[2]"
                        :label="ct.nif[1]"
                        :disabled="noEdit">                      
                      </uiText>
                    </div>

                    <div class="conflex">
                      <uiText
                        v-bind="$input" 
                        style="width:69%"                    
                        v-model="ct.dir[2]"
                        :label="ct.dir[1]"
                        :disabled="noEdit">                      
                      </uiText>

                      <uiText
                        v-bind="$input" 
                        style="width:30%;margin-left:5px"                    
                        v-model="ct.cpo[2]"
                        :label="ct.cpo[1]"
                        :disabled="noEdit">                      
                      </uiText>                      
                    </div>

                    <uiText
                      v-bind="$input" 
                      v-model="ct.pob[2]"
                      :label="ct.pob[1]"
                      :disabled="noEdit">                      
                    </uiText>

                    <div class="conflex">
                      <uiText
                        v-bind="$input" 
                        style="width:33%;"                    
                        v-model="ct.tlf1[2]"
                        :label="ct.tlf1[1]"
                        :disabled="noEdit">
                      </uiText>                          

                      <uiText
                        v-bind="$input" 
                        style="width:32%;margin-left:5px"                    
                        v-model="ct.mov1[2]"
                        :label="ct.mov1[1]"
                        :disabled="noEdit">
                      </uiText>
                          
                      <uiText
                        v-bind="$input" 
                        style="width:32%;margin-left:5px"                    
                        v-model="ct.fax1[2]"
                        :label="ct.fax1[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>

                    <div class="conflex">
                      <uiText
                        v-bind="$input" 
                        style="width:33%;"                    
                        v-model="ct.tlf2[2]"
                        :label="ct.tlf2[1]"
                        :disabled="noEdit">
                      </uiText>                          

                      <uiText
                        v-bind="$input" 
                        style="width:32%;margin-left:5px"                    
                        v-model="ct.mov2[2]"
                        :label="ct.mov2[1]"
                        :disabled="noEdit">
                      </uiText>
                          
                      <uiText
                        v-bind="$input" 
                        style="width:32%;margin-left:5px"                    
                        v-model="ct.fax2[2]"
                        :label="ct.fax2[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>

                    <div class="conflex">
                      <uiText
                        v-bind="$input"
                        v-model="ct.email1[2]"
                        :label="ct.email1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText
                        v-bind="$input"
                        style="margin-left:5px"
                        v-model="ct.email2[2]"
                        :label="ct.email2[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>

                    <uiText
                      v-bind="$input" 
                      v-model="ct.zona[2]"
                      :label="ct.zona[1]"
                      :disabled="noEdit">                      
                    </uiText>
                  
                    <div class="conflex" style="align-items:center">                       
                      <uiPass                         
                        :ct="ct.passwd"                        
                        :disabled="noEdit">
                      </uiPass>                      
                      
                      <uiPass               
                        styleComp="margin-left:5px"          
                        :ct="ct.passwd_sus"                        
                        :disabled="noEdit">
                      </uiPass>
                      
                      <v-checkbox
                        v-bind="$checkbox"
                        style="margin-left:5px"
                        v-model="ct.web[2]"
                        :label="ct.web[1]"
                        :disabled="noEdit">
                      </v-checkbox>                
                    </div>

                    <v-textarea
                      v-bind="$textarea"
                      v-model="ct.obs[2]"
                      :label="ct.obs[1]"
                      no-resize
                      :disabled="noEdit">          
                    </v-textarea>

                    <uiDate 
                      style="width:30%"
                      v-model="ct.fhalta[2]"
                      :label="ct.fhalta[1]" 
                      :disabled="noEdit">
                    </uiDate>                    
                  </div>

                  <div class="columna" style="padding-left:20px;width:290px;align-items:center">                       
                    <div class="cab" style="width:198px;margin-right:8px;border-radius:0px">FOTO</div>          
                    <v-sheet v-bind="$cfg.styles.marco">                      
                      <uiImg                            
                        max-height="220"
                        width="180"
                        :ct="ct.foto" 
                        @change="changeImg"                          
                        :disabled="estado!=='nuevo'">
                      </uiImg>
                    </v-sheet>                    

                    <div class="cab" style="margin-top:10px;width:272px;margin-right:7px;;border-radius:0px">OTROS DATOS</div>          
                    <v-sheet v-bind="$cfg.styles.marco" style="width:100%">
                      <uiDate 
                        v-model="ct.fhnac[2]"                       
                        :label="ct.fhnac[1]" 
                        :disabled="!noEdit">
                      </uiDate>
                    
                      <v-select
                        v-bind="$select"
                        v-model="ct.ecivil[2]"
                        :label="ct.ecivil[1]"
                        :items="getEstadoCivil"
                        :disabled="noEdit"
                        item-value="id"
                        item-text="name">          
                      </v-select>

                      <uiText
                        v-bind="$input"                    
                        v-model="ct.catpro[2]"
                        :label="ct.catpro[1]"                   
                        :disabled="noEdit">
                      </uiText>

                      <v-textarea
                        v-bind="$textarea"
                        v-model="ct.estudios[2]"
                        no-resize
                        :label="ct.estudios[1]"
                        :disabled="noEdit">          
                      </v-textarea>
                      
                      <uiDate 
                        v-model="ct.acceso[2]"                      
                        :label="ct.acceso[1]" 
                        type="datetime-local"
                        :disabled=true>
                      </uiDate>                      
                    </v-sheet>  
                  </div>
                </div>
              </div>            
            </v-sheet>


            <!-- Ventana de Componentes Dinámicos  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"              
              :readOnly="readOnly"              
              tipoDocumento='11'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component> 
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  import dualTemplate from "@/components/dualTemplate";
  import commonDocsF from "@/components/commonDocsF.vue";

  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, baseBtraExtra, dualTemplate, commonDocsF },
    props: {},

    data() {
      return {
        stIni: {
          api: "jefeszonaM",
          name:"jefeszonaM",
          titulo:"Coordinador de Servicios - Mantenimiento",  
          recordAccess:"local",
          mView:'',
          pView:[]
        },      
      };
    },

    

    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" }         
        ];                     
        
      },


      //
      iniDataApi() {
        this.apiArgs.imagenSet= ["", "imagenDB", { fnt:'set', tip:0, tip_id:0 }, "aplica"];
        this.apiArgs.imagenDel= ["", "imagenDB", { fnt:'del', tip:0, tip_id:0 }, "aplica"];
      },

            
      // entra al cambiar la imagen de la foto
      // guarda imagen
      changeImg() {
        this.guardarImagen();
      }, 
      

      // guarda / elimina imagen
      async guardarImagen() {
        let api= this.ct.foto[4].comp.files=== -1? 'imagenDel' : 'imagenSet';

        // apiCall        
        let args= await this.$store.dispatch("apiCallComposicion", { apiArg: this.apiArgs[api], args:{ tip:this.ct.foto[4].comp.tip, tip_id:this.record.id }});      
        args.args= JSON.stringify(args.args);
            
        // pasa los argumentos a FORMDATA
        let formData = new FormData();
        for (const key in args) {
          formData.append(key, args[key]);
        }

        // añade la imagen a formData       
        formData.append("DOCUM", this.ct.foto[4].comp.files);
        formData.append("token", sessionStorage.getItem("login") || null);
        
        // ejecuto llamada API        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: formData });     
        if (apiResult.sql.error) this.$root.$alert.open(apiResult.sql.msg, 'error');
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },
      
    },


    computed: {
     
      // A la espera de lo que diga Miguel. Data provider para el combo de grupo
      getEstadoCivil() {
        return [
          { id: "0", name: "" },
          { id: "1", name: "Soltero/a" },
          { id: "2", name: "Casado/a" },
          { id: "3", name: "Divorciado/a" },
          { id: "4", name: "Viudo/a" }
        ];
      }

    },

  };
</script>
